.button {
    padding: 1em;
    border: solid 1px;
    border-radius: 10% / 50%;
}

.hidden {
    display: none;
}

#file-upload-header {
    margin-top: 10%;
}

#game-container {
    height: 100%;
    display: flex;
    justify-content: center;
}

#accents-stack {
    display: flex;
    justify-content: center;
}

#accents-stack * {
    border-radius: 16px;
    min-width: 25px;
    max-width: 100px;
    flex-grow: 1;
}

#menu-container {
    width: 45%;
    display: flex;

    flex-direction: column;
}

#audio-player {
    display: flex;
    align-items: center;
}

#progress-bar {
    margin-top: 20%;
    display: flex;
    align-items: center;
}

#next-button {
    margin-top: 5%;
}

#difficulty-hint-stack {
    margin-top: 5%;
    margin-left: 5%;
    margin-right: 5%;
    display: flex;
    justify-content: space-between;
}

#correct-sentence {
    text-align: center;
}

#loading-text {
    text-align: center;
    font-size: 5em;
}

/* for mobile devices in portrait mode */
@media only screen and (max-width: 600px) {
    #menu-container {
        width: 90%;
    }

    #loading-text {
        font-size: 3em;
    }
}

@media only screen and (min-width: 600px) and (max-width: 1100px) {
    #menu-container {
        width: 70%;
    }
}