#game-over-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

#game-over-text {
    font-size: 48px;
    margin-bottom: 0;
}

#final-score-text {
    font-size: 20px;
}