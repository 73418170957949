body {
  	margin: 0;
  	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    	'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    	sans-serif;
  	-webkit-font-smoothing: antialiased;
  	-moz-osx-font-smoothing: grayscale;
	background-color: var(--theme-background-colour);

	display: flex;
	flex-direction: column;
}

:root {
	--theme-colour: whitesmoke;
	--theme-background-colour: rgb(37, 37, 37);
}

.changes-theme {
	color: var(--theme-colour);
}

code {
  	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    	monospace;
}

#NavBar {
	display: flex;
	gap: 2%;
	padding: 1%;
	align-items: center;
}

#right-align-navbar {
	display: flex;
	flex-grow: 1;
	gap: 1%;
	justify-content: flex-end;
}

#left-align-navbar {
	display: flex;
	gap: 5%;
	flex-grow: 1;
}

#NavBar * {
	font-weight: 600;
	font-size: 20px;
	text-decoration: none;
}

#NavBar a:hover {
	font-weight: 700;
}

#Footer {
	display: flex;
	justify-content: center;
	width: 100%;

	/* put the footer at the bottom of the page */
	margin-top: 5%;
	flex-shrink: 0;
}

#Footer * {
	font-size: 0.9em;
	width: 33%;
	margin-bottom: 3%;
	overflow-wrap: break-word; 
}

@media only screen and (max-width: 400px) {
	#NavBar * {
		font-weight: 600;
		font-size: 15px;
		text-decoration: none;
	}
}

/* for mobile devices in portrait mode */
@media only screen and (max-width: 600px) {
    #Footer * {
		width: 45%;
		padding: 2%;
	}
}

@media only screen and (max-height: 700px) {
	#Footer {
		display: none;
	}
}