#header {
    font-size: 5em;
}

#header, #subheader {
    text-align: center;
}

#subheader-container {
    display: flex;
    justify-content: center;
}

#subheader {
    font-weight: normal;
    font-size: 1.5em;
    width: 50%;
}

#start-game-stack {
    margin-top: 10%;
    display: flex;
    justify-content: center;
}

#start-game-widgets {
    width: 15%;
}

/* for mobile devices in portrait mode */
@media only screen and (max-width: 600px) {
    #subheader {
        width: 90%;
    }

    #header {
        font-size: 3em;
    }

    #start-game-widgets {
        width: 75%;
    }
}